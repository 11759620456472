<template>
    <div class="wrap">
        <div class="space_pc">
            <header class="header space">
                <div class="header_box">
                    <div class="logo">
                        <router-link
                            :to="{
                                name: 'Home'
                            }"
                        >
                            <img src="/media/img/logo.svg" alt="" />
                            <!-- <img src="/media/img/logo_beta.svg" alt=""> -->
                        </router-link>
                    </div>
                </div>
            </header>
            <section class="main error">
                <div class="error_box">
                    <h1>404 Error</h1>
                    <p>요청하신 페이지를 찾을 수 없습니다.<br />입력하신 주소가 정확한지 다시 한번 확인해주세요.</p>
                </div>

                <div class="caution_img">
                    <div class="caution_ball">
                        <div><img src="/media/img/caution_img.svg" alt="" /></div>
                        <img src="/media/img/space_loding_img2.png" alt="" />
                    </div>
                    <button @click="clickPre()"><a>이전화면으로 돌아가기</a></button>
                </div>
            </section>
        </div>
        <div class="space_mo h_100">
            <!-- <div class="mo_space_header">
                <div class="mo_header_box">
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.828 7.00072L7.778 11.9507L6.364 13.3647L0 7.00072L6.364 0.636719L7.778 2.05072L2.828 7.00072Z" fill="white"/>
                    </svg>
                    <h2>모바일 타이틀</h2>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.00023 5.58672L11.9502 0.636719L13.3642 2.05072L8.41423 7.00072L13.3642 11.9507L11.9502 13.3647L7.00023 8.41472L2.05023 13.3647L0.63623 11.9507L5.58623 7.00072L0.63623 2.05072L2.05023 0.636719L7.00023 5.58672Z" fill="white"/>
                    </svg>
                </div>
            </div> -->

            <section class="main error">
                <div class="error_box">
                    <h1>404 Error</h1>
                    <p>요청하신 페이지를 찾을 수 없습니다.<br />입력하신 주소가 정확한지 다시 한번 확인해주세요.</p>
                    <div class="caution_img">
                        <div class="caution_ball">
                            <div><img src="/media/img/caution_img.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                        <button @click="clickPre()"><a>이전화면으로 돌아가기</a></button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  name: '404',
  methods: {
    clickPre () {
      history.back();
    }
  }
};
</script>

<style scoped>
.main.error {
  width: 100%;
  padding-top: 132px;
}
.error .error_box {
  width: 100%;
  margin: 0 auto;
  max-width: 940px;
}
.error h3 {
  color: #fff;
  font-size: 38px;
  line-height: 1.6;
  text-align: center;
}
.error h3 span {
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.11);
  font-weight: 500;
  border-radius: 4px;
}
.caution_img {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.caution_img > div {
  display: block;
  position: relative;
  margin: 100px 0 50px;
}
.caution_img button {
  background: none;
  border: none;
}
.caution_img button a {
  font-size: 14px;
  text-decoration: underline;
  color: #fff;
  background: none;
}

.caution_ball {
  align-items: center;
  display: block;
  width: 200px;
  height: 190px;
  margin: 60px 0 !important;
}
.caution_ball div {
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(30px);
  animation: rotate-in-down-left 2s ease;
}
.caution_ball img:nth-child(2) {
  position: absolute;
  bottom: -20px;
  right: -10px;
  z-index: 1;
  animation: rotate-in-up-left 2s ease;
}

/*error page*/
.error_box {
  width: 540px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.error_box h1 {
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  margin-bottom: 24px;
  text-align: center;
}
.error_box input {
  width: 100%;
  margin-bottom: 8px;
}
.error_box > p {
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  text-align: center;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
